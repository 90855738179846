.videos-carousel-container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 160px;
}

.fullwidth-carousel .videos-carousel-container {
  max-width: 100%;
}