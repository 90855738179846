.business-container {
    width: 100%;
    position: relative;
    padding-top: 56%;
    border-radius: 32px;
    border-bottom: 1px solid #41424e;
    overflow: hidden;
    backdrop-filter: blur(20px);
    pointer-events: none;
    transition: all 0.55s cubic-bezier(.32, -.01, .15, .98);
    margin-bottom: -198px;
}

.business-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(20 23 56 / 35%);
    z-index: 10;
}

.business-container.business-playing:before {
    background: rgb(20 23 56 / 35%);
}

.business-container.business-playing-loud:before {
    content: none;
}

.business-container.business-playing .business-preview-video {
    /*display: none;*/
}

.business-container.business-playing-loud {
    pointer-events: initial;
    transform: scale3d(1.24, 1.24, 1.24) translate(0px, -9.72%);
    border-radius: 30px;
}

.business-container.business-playing-loud .business-name {
    visibility: hidden;
}

.business-container.business-playing-loud .business-video-close {
    display: block;
}

.business-container.business-playing-loud .business-video:before {
    content: none;
}

.business-container.business-preview .business-image img {
    opacity: 1;
}

.business-name {
    position: absolute;
    bottom: initial;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Ppmonumentextended, sans-serif;
    color: #fff;
    font-size: 46px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    z-index: 2;
    visibility: hidden;
}

.business-preview-video {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    transform: translate(-50%, 0);
    z-index: 2;
    transition: all 150ms;
}

.business-container:not(.video-Watch-the-whole-story):not(.video-Vezi-întreaga-poveste) .business-preview-video {
    /*display: none;*/
    opacity: 0;
}

.business-container.video-Watch-the-whole-story .business-image,
.business-container.video-Vezi-întreaga-poveste .business-image {
    display: none;
}

.business-image img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 1;
    z-index: 1;
}

.business-video {
    position: absolute;
    width: 100% !important;
    height: calc(100% + 160px) !important;
    position: absolute;
    top: -80px;
    left: 0;
    z-index: 5;
}

.business-video-close {
    position: absolute;
    right: 2.6%;
    top: 4%;
    width: 36px;
    cursor: pointer;
    display: none;
    z-index: 8;
}

.carousel-container {
    position: relative;
    /*bottom: 198px;*/
    width: calc(100% + 140px);
    left: -70px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #1a1b1f !important;
}


/* Animations*/
.fade-in {
    animation: fadeIn 1.15s cubic-bezier(0, 0, .61, 1.06);
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@media screen and (max-width: 1466px) {
    .business-video-close {
        right: calc((1466px - 94vw)/2 + -30px)
    }
}

@media screen and (max-width: 1339px) {
    .carousel-container {
        width: 100vw;
        left: 50%;
        transform: translate(-50vw);
        padding: 0 12px;
        box-sizing: border-box;
    }
    .business-container{
        margin-bottom: -208px;
    }
}

@media screen and (max-width: 1199px) {
    .network-fold .fold-title-section {
        padding: 38px 0px 50px;
        margin-top: 65px;
    }

    .business-video-close {
        right: 10.5vw;
    }
}

@media screen and (max-width: 991px) {
    .carousel-container {
        bottom: 0 !important;
    }

    .network-fold-title {
        color: #101120;
        margin-bottom: -22px;
    }

    .network-fold .fold-title-section {
        margin-top: 35px;
    }
    .videos-carousel-container{
        padding-top: 0;
    }
}