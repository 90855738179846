.card-carousel {
    position: relative;
    height: 266px;
    border-radius: 32px;
    cursor: pointer;
    background: #101120;
    opacity: 0.9;
    transition: all 0.17s cubic-bezier(.32, -.01, .15, .98);
    overflow: hidden;
}

.card-carousel.card-inactive {
    background: rgba(255, 255, 255, 0.07) 0% 0% no-repeat padding-box !important;
}

.card-carousel .card-content {
    transition: all 0.17s cubic-bezier(.32, -.01, .15, .98);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    left: 38px;
    position: absolute;
    text-align: left;
    top: 24px;
    width: 80%;
    z-index: 2;
}

.card-carousel.card-inactive .card-content {
    opacity: 0;
}

.business-slider .card-name {
    position: absolute;
    padding: 20px 38px;
    border-top: 1px solid #ffffff21;
    left: 0;
    top: auto;
    right: 0%;
    bottom: 0;
    color: #000;
    font-size: 18px;
    line-height: 28px;
    transition: all 0.17s cubic-bezier(.32, -.01, .15, .98);
    text-align: left;
    font-weight: 700;
}

.card-carousel.card-active .card-name {
    color: #fff;
}

.business-slider .card-title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    color: #f2f2f2;
    margin: 0;
    font-family: 'Mulish', sans-serif;
}

.business-slider .card-text {
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
    font-weight: 300;
    font-family: 'Mulish', sans-serif;
    margin-top: 10px;
}

.card-carousel .card-link {
    color: #fff;
    font-weight: 600;
    width: fit-content;
}

.card-carousel .card-link:hover {
    color: #fff;
}

.card-video-player-mobile-wrapper {
    position: relative;
    padding-top: 56.25%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
}

.card-video-player-mobile {
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 5;
    /*pointer-events: none;*/
}

.card-playing .card-content {
    opacity: 0;
}

.card-playing .card-video-player-mobile-wrapper {
    opacity: 1;
}

.card-play-icon {
    width: 44px !important;
    position: absolute;
    right: 8%;
    bottom: 13px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.45s;
    opacity: 0;
}

.card-play-icon:hover {
    filter: drop-shadow(1px 2px 4px rgba(255, 255, 255, 0.73));
}

.card-carousel.card-active .card-play-icon {
    opacity: 1;
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 14px;
}

@keyframes grow {

    0%,
    100% {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.15, 1.15, 1.15);
    }
}

.card-carousel:hover .card-play-icon {
    animation: grow 0.45s ease-out;
}

.business-slider .owl-item:nth-child(3) .card-title {
    width: 58%;
}

.business-slider .owl-item:nth-child(6) .card-title {
    width: 67%;
}

.business-slider .owl-item:nth-child(8) .card-title {
    width: 90%;
}

.business-slider .owl-item:nth-child(9) .card-title {
    width: 60%;
}

.business-slider .owl-item:nth-child(10) .card-title {
    width: 63%;
}

.lang-ro .business-slider .owl-item:nth-child(3) .card-title {
    width: 100%;
}

.business-slider .owl-item:nth-child(6) .card-title {
    width: 100%;
}

.business-slider .owl-item:nth-child(9) .card-title {
    width: 100%;
}

.business-slider .owl-item:nth-child(10) .card-title {
    width: 100%;
}

.card-video-close {
    position: absolute;
    right: 2.6%;
    top: 2%;
    width: 36px !important;
    cursor: pointer;
    display: none;
    z-index: 8;
}

.business-slider .card-title,
.business-slider .card-name {
    font-family: 'Mulish', sans-serif;
}

.business-slider .card-content {
    grid-column-gap: 0;
    grid-row-gap: 0;
}

.business-slider .card-text {
    letter-spacing: 0;
    font-weight: 400;
    color: #d0d2dd;
}

@media screen and (max-width: 1339px) {
    .business-slider .owl-item:nth-child(3) .card-title {
        width: 78%;
    }

    .card-carousel {
        height: 276px;
    }

    .carousel-container {
        /*bottom: 208px;*/
    }

    .business-slider .card-title {
        font-size: 18px;
        line-height: 26px;
    }

    .business-slider .card-name {
        font-size: 18px;
    }
}

@media screen and (max-width: 1199px) {
    .business-slider .card-title {
        font-size: 18px;
    }

    .business-slider .card-name {
        font-size: 18px;
    }
}

@media screen and (max-width: 991px) {

    .business-slider .card-name {
        font-size: 18px;
    }

    .business-slider .card-play-icon {
        top: 50%;
        right: initial;
        transform: translate(-50%, -50%);
        left: 50%;
        width: 64px !important;
        height: auto !important;
        animation: none !important;
    }

    .business-slider .card-content {
        opacity: 1;
        top: 384px;
        bottom: initial;
    }

    .business-slider .card-carousel {
        height: 630px;
    }

    .business-image img {
        top: 0;
        opacity: 1;
    }

    .card-video-player-mobile {
        width: 180vw !important;
        height: 63vw !important;
        top: -57px;
    }

    .business-image {
        height: 360px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
    }

    .card-video-player-mobile-wrapper {
        padding-top: 360px;
        height: initial;
    }

    .business-image img {
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 100% !important;
        height: auto;
    }
}

@media screen and (max-width: 767px) {
    .business-slider .card-content {
        left: 20px !important;
    }

    .business-slider .card-name {
        padding: 20px 24px !important;
    }
}

@media screen and (max-width: 669px) {
    .business-slider .card-carousel {
        height: 530px;
    }

    .card-video-player-mobile {
        width: 180vw !important;
        height: 55vw !important;
        top: -57px;
    }

    .card-video-player-mobile-wrapper {
        padding-top: 262px;
        height: initial;
    }

    .business-slider .card-content {
        top: 284px;
        bottom: initial;
    }

    .business-image {
        height: 262px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
    }

    .business-slider .card-play-icon {
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }
}

@media screen and (max-width: 580px) {
    .card-video-player-mobile {
        width: 180vw !important;
        height: 75vw !important;
        top: -57px;
    }
}

@media screen and (max-width: 479px) {
    .card-video-player-mobile-wrapper {
        padding-top: 75vw;
        height: auto;
    }
    .business-slider .card-text{
        width: 100% !important;
        padding-right: 0 !important;
    }

    .business-slider .card-carousel {
        height: calc(526px + 6vw);
    }

    .business-slider .card-content {
        top: calc(55vw + 47px);
        bottom: initial;
        left: 8vw;
        width: 83%;
    }

    .business-image {
        height: calc(75vw - 58px);
    }

    .business-image img {
        top: 0 !important;
        left: 50% !important;
        transform: translate(-50%, 0) !important;
        width: auto !important;
        height: 100% !important;
        max-width: initial !important;
    }

    .business-slider .card-name {
        padding-left: 8vw;
    }
}